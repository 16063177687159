import React, {useRef, useState} from 'react'
import {Link} from 'gatsby'
import { useForm } from 'react-hook-form'

import CustomSelect from './customSelect'
import getOffsetEl from '../utils/getOffsetEl'

const ContactSection = () => {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur'
  })
  const [formState, setFormState] = useState('initial')

  const formWrapper = useRef(null)

  const onSubmit = (data, e) => { 
    e.preventDefault()
    let formData = new FormData(document.getElementById('contact'))

    if(formData.get('contact[subject') === null) {
      formData.append('contact[subject]', document.querySelector('.react-select-container.is-contact .react-select__single-value').textContent)
    }

    fetch(`${process.env.GATSBY_API_URL}/api/contact`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString()
    })
      .then(() => {
        setFormState('success')

        if(window.innerWidth < 768) {
          window.scroll({
            top: getOffsetEl(formWrapper.current) - 60, 
            left: 0, 
            behavior: 'smooth'
          })
        }
      })
      .catch(error => setFormState('error'))
	}

  const optionsObject = [
    { 'value': 'Je souhaite en savoir plus sur l\'observatoire', 'label': 'Je souhaite en savoir plus sur l\'observatoire' },
    { 'value': 'Je souhaite entrer en contact avec l\'agence Big Youth', 'label': 'Je souhaite entrer en contact avec l\'agence Big Youth' },
    { 'value': 'Ma marque figure dans l\'observatoire, j\'aimerais avoir plus de détails', 'label': 'Ma marque figure dans l\'observatoire, j\'aimerais avoir plus de détails' },
    { 'value': 'Je souhaite que ma marque figure dans la prochaine édition dde l\'observatoire', 'label': 'Je souhaite que ma marque figure dans la prochaine édition de l\'observatoire' },
    { 'value': 'Autre', 'label': 'Autre' }
  ]

  return (
    <div className="contact full-section">
      <div className="fluid-grid">
        <div className="row">
          <div className="w-full lg-col-3 xl-col-1">
            <h2 className="title-section mb-6 md:mb-11">Contact</h2>

            <p className="emphasis">Vous souhaitez plus de détails sur les résultats&nbsp;?<br/> Un audit personnalisé&nbsp;?</p>
          </div>

          <div className="w-full lg-col-3 xl-col-2" ref={formWrapper}>
            { formState !== 'success' &&
              <>
                <p className="italic text-right mb-6 mt-8 xl:mt-0">* Champs obligatoires</p>
                <form className="form" noValidate id="contact" name="contact" method="POST" onSubmit={handleSubmit(onSubmit)}>
                  {/* name + firstname  */}
                  <div className="md:flex justify-between">
                    <div className="form__half">
                      <div className="form__item">
                        <label htmlFor="name" className="form__label">Nom<sup>*</sup></label>
                        <input className={errors.contact && errors.contact.lastName ? 'has-error' : ''} type="text" name="contact[lastName]" id="name" ref={register({ required: true })} />
                        {errors.contact && errors.contact.lastName && <span className="form__error">Ce champ est obligatoire</span>}
                      </div>
                    </div>
                    <div className="form__half">
                      <div className="form__item">
                        <label htmlFor="firstname" className="form__label">Prénom<sup>*</sup></label>
                        <input className={errors.contact && errors.contact.firstName ? 'has-error' : ''} type="text" name="contact[firstName]" id="firstname" ref={register({ required: true })} />
                        {errors.contact && errors.contact.firstName && <span className="form__error">Ce champ est obligatoire</span>}
                      </div>
                    </div>
                  </div>

                  {/* company + job  */}
                  <div className="md:flex justify-between">
                    <div className="form__half">
                      <div className="form__item">
                        <label htmlFor="company" className="form__label">Société</label>
                        <input type="text" name="contact[company]" id="company" />
                      </div>
                    </div>
                    <div className="form__half">
                      <div className="form__item">
                        <label htmlFor="job" className="form__label">Poste</label>
                        <input type="text" name="contact[job]" id="job" />
                      </div>
                    </div>
                  </div>
                  
                  {/* mail + tel */}
                  <div className="md:flex justify-between">
                    <div className="form__half">
                      <div className="form__item">
                        <label htmlFor="mail" className="form__label">Email<sup>*</sup></label>
                        <input 
                          className={errors.contact && errors.contact.email ? 'has-error' : ''}
                          type="email"
                          name="contact[email]"
                          id="mail"
                          ref={register({ 
                            required: {
                              value: true,
                              message: 'Ce champ est obligatoire'
                            }, 
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: 'Le format de l\'email est invalide'
                            }
                          })}  />
                        
                        {errors.contact && errors.contact.email && <span className="form__error">{errors.contact && errors.contact.email.message}</span>}
                      </div>
                    </div>
                    <div className="form__half">
                      <div className="form__item">
                        <label htmlFor="tel" className="form__label">Tél.</label>
                        <input 
                          className={errors.contact && errors.contact.tel ? 'has-error' : ''}
                          type="tel"
                          name="contact[tel]"
                          id="tel"
                          ref={register({ 
                            pattern: {
                              value: /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/i,
                              message: 'Le format du téléphone est invalide'
                            }
                          })}  />
                        {errors.contact && errors.contact.tel && <span className="form__error">{errors.contact && errors.contact.tel.message}</span>}
                      </div>
                    </div>
                  </div>
                  
                  {/* object */}
                  <div className="form__item">
                    <label htmlFor="contact[subject]" className="form__label">Objet</label>

                    <CustomSelect
                      id="contact[subject]"
                      name="contact[subject]"
                      options={optionsObject}
                      cssClass="is-contact"
                    />
                  </div>
                  
                  {/* msg */}
                  <div className="form__item">
                    <label htmlFor="msg" className="form__label form__label--msg">Message</label>
                    
                    <textarea name="contact[message]" id="msg" />
                  </div>

                  {/* checkbox */}
                  <div className="form__item">
                    <label className="custom-checkbox custom-checkbox--contact">
                      <input name="rgpd" type="checkbox" ref={register({ required: true })}/>
                      <span className="custom-checkbox__label">J'accepte que la direction de la communication de Big Youth traite les données recueillies dans le cadre de ma demande de contact. Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits, <Link to="/vos-donnees">cliquez-ici</Link>.</span>
                    </label>
                    {errors.rgpd && <span className="form__error">Ce champ est obligatoire</span>}
                  </div>

                  { formState === 'error' &&
                    <p className="paragraph form__global-error text-right mb-4">Oups, une erreur est survenue.</p>
                  }
                  
                  <div className="flex justify-end mt-6">
                    <button type="submit" className="button button--white button--contact">Envoyer</button>
                  </div>
                </form>
              </>
            }

            { formState === 'success' &&
              <>
                <p className="paragraph mt-8 lg:mt-0">Votre demande a bien été prise en compte.<br/> Nous reviendrons vers vous dans les plus brefs délais.</p>
                <button onClick={() => setFormState('initial')} className="button button--white button--contact mt-6">Nouvelle demande de contact</button>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactSection
