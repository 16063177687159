import * as React from 'react'
import Helmet from 'react-helmet'

import { useStaticQuery, graphql } from 'gatsby'

const Breadcrumb = ({label, url, type, year = '2022'}) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            baseUrl
          }
        }
      }
    `
  )

  const baseUrl = data.site.siteMetadata.baseUrl

  return (
    <Helmet>
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Accueil",
              "item": "${baseUrl}${year !== '2022' ? `/${year}` : ''}"
            }${type !== 'ranking' && type !== 'hp' && type !== 'method' && type !== 'demarche' && type !== 'credits' && type !== 'datas' ? `,{
              "@type": "ListItem",
              "position": 2,
              "name": "Classement",
              "item": "${baseUrl}${year !== '2022' ? `/${year}` : ''}/classement"
            }` : ''}${type === 'method' ? `,{
              "@type": "ListItem",
              "position": 2,
              "name": "Notre démarche",
              "item": "${baseUrl}${year !== '2022' ? `/${year}` : ''}/notre-demarche"
            }` : ''}${type !== 'hp' ? `,{
              "@type": "ListItem",
              "position": ${type !== 'ranking' && type !== 'demarche' && type !== 'credits' && type !== 'datas' ? 3 : 2 },
              "name": "${label}",
              "item": "${baseUrl}${url}"
            }` : ''}]
          }
        `}</script>
      </Helmet>
  )
}

export default Breadcrumb