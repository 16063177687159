import React, {useState, useEffect} from 'react'

import Select, { components } from 'react-select'
import isMobile from 'ismobilejs'

const CustomSelect = ({options, onChange, onChangeMobile = false, id, cssClass}) => {
  let [isCustomSelect, setIsCustomSelect] = useState(false)

  const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <svg className={`react-select__icon ${props.selectProps.menuIsOpen && 'is-open'}`} width="16" height="10" xmlns="http://www.w3.org/2000/svg"><path d="M14 0l2 2-8 8-8-8 2-2 6 6z" /></svg>
        </components.DropdownIndicator>
        )
    )
  }

  useEffect(() => {
    if(typeof window !== 'undefined') {
      setIsCustomSelect(!isMobile(window.navigator).any) 
    }
  }, [])

  return (
    <>
    { isCustomSelect ?
      <Select 
        components={{ DropdownIndicator }}
        options={options}
        onChange={onChange}
        defaultValue={options[0]}
        className={`react-select-container ${cssClass ? cssClass : ''}`}
        classNamePrefix="react-select"
        isSearchable={false}
        inputId={id} />
      :
      // eslint-disable-next-line
      <select id={id} name={id} className={`mobile-select ${cssClass ? cssClass : ''}`} onChange={(e) => onChangeMobile ? onChangeMobile(e) : ''}>
        {options.map((option, i) => {
          return (
            <option key={i} value={option.value}>{option.label}</option>
          )
        })}
      </select>
    }
    </>
  )
}

export default CustomSelect
