const GetOffsetEl = (el) => {
  let offset = 0

  while(true) {
    offset += el.offsetTop
    
    if(el.offsetParent === null) break;
    el = el.offsetParent
  }

  return offset
}

export default GetOffsetEl